import { useState, useEffect, createRef } from "react";
import {
  Grid,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  Modal,
} from "@mui/material";
import Shell from "components/shell";
import { BorderButton, FilledButton, SubmitButton } from "components/buttons";
import {
  formatPhoneNumber,
  formatDate,
  containsUppercase,
  hasLowerCase,
  checkNumber,
  eachObjectKeyTrue,
  checkPassword,
  getFormatedSelectInputValueForEnrolmentForm,
} from "utilities";
import classes from "./index.module.css";
import getTextConstants, { getSubTitle } from "constants/copy";
import { isFormValid, Input, getFormValues } from "components/controls";
import { nameValidator } from "utilities";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  updateRegistration,
  updateDependentInvite,
  updateDependentEnrolment,
  profileAPI,
} from "../../data";
import { ErrorModal } from "components/errorModal";
import {
  SUBJECT_EMPLOYEE,
  SUBJECT_DEPENDENT,
  SPONSOR_HP,
  SPONSOR_CVS,
  NO_USER_FOUND,
  USER_NOT_EXIST_CODE,
  USER_ERRORED_CODE_GENERAL_1,
  USER_EXIST_CODE_GENERAL_2,
  SUBJECT_PROVIDER,
  BOLD,
  USER_EXIST_CODE_CVS_1,
  SPONSOR_AC,
  SPONSOR_ACHQ,
  CHAT_USER_NOT_CREATED,
  USER_DEPENDENT_ERROR_1,
  USER_DEPENDENT_ERROR_2,
  USER_DEPENDENT_ERROR_3,
  USER_DEPENDENT_ERROR_4,
  SPONSOR_NITERRA,
} from "../../constants/app";
import ErrorImage from "../../assets/img/Vector (4).png";
import { useDispatch, useSelector } from "react-redux";
import DependentSuccessImage from "../../assets/img/Success.png";
import StepperMessage from "components/stepperMessage";
import Account from "components/account";
import Shipping from "components/shipping";
import { userInfoAction } from "store/actions";
import { Login } from "components/login";
import ErrorBox from "components/ErrorBox";
import { feet, inches, screenNames, sex } from "constants/enrollment";
import { DateTime } from "luxon";
import * as textConstants from "./constant";

const Register = (props) => {
  let [subject, setSubject] = useState(useParams().subject);
  const [registrationValues, updateRegistrationValues] = useState({});
  const [errors, updateErrors] = useState([]);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [confirmPasswordOpen, setConfirmPasswordOpen] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dependentEmails, updateDependentEmails] = useState([]);
  const [submitable, updateSubmitable] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [credentialsErros, setCredentialsErros] = useState();
  const [passwordValidation, setPasswordValidation] = useState(null);
  const [confirmPasswordValidation, setConfirmPasswordValidation] =
    useState(null);
  const [dependentSuccess, setDependentSuccess] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState({
    open: false,
    target: null,
    message: null,
    code: null,
  });
  const user = useSelector((state) => state.user);

  const handleOpen = (message, code, smallBox) =>
    setOpen({ open: true, message: message, code: code, smallBox: smallBox });
  const handleClose = () => setOpen({ open: false });
  const formRef = createRef();
  let { code } = useParams();
  let sponsor = useParams().sponsor.toUpperCase();
  const navigate = useNavigate();

  // if(!subjects.includes(subject)) subject = subjects[0];

  const messages = {
    title: getTextConstants(`enrollment.title.${subject}`, [
      "BOLD Capital Partners",
    ]),
    subtitle: getTextConstants(`enrollment.subtitle.${subject}`),
    stepper: getTextConstants(`enrollment.stepper.${subject}`),
    formTitle: getTextConstants(`enrollment.form.title.${subject}`),
    formSubtitle: getTextConstants(
      `enrollment.form.subtitle.${subject}`,
      null,
      ""
    ),
    allFieldsRequired: getTextConstants(
      `enrollment.form.allFieldsRequired.${subject}`
    ),
    firstNameLabel: getTextConstants(`enrollment.form.firstName.${subject}`),
    lastNameLabel: getTextConstants(`enrollment.form.lastName.${subject}`),
    dateOfBirthLabel: getTextConstants(`enrollment.form.dateOfBirth.title`),
    dateOfBirthSubtext: getTextConstants(
      `enrollment.form.dateOfBirth.subtitle`
    ),
    accountFormTitle: getTextConstants(
      `enrollment.form.accountFormTitle.${subject}`
    ),
    height: getTextConstants(`enrollment.form.height.title`),
    heightSubtext: getTextConstants(`enrollment.form.height.subtitle`),
    sex: getTextConstants(`enrollment.form.sex.title`),
    sexSubtext: getTextConstants(`enrollment.form.sex.subtitle`),
    passwordLabel: getTextConstants(`enrollment.form.password.${subject}`),
    confirmPassowrdLabel: getTextConstants(
      `enrollment.form.confirmPassword.${subject}`
    ),
    emailLabel: getTextConstants(`enrollment.form.email.${subject}`),
    emailSubText: getTextConstants(`enrollment.form.email.subText`),
    dependentEmailLabel: getTextConstants(
      `enrollment.form.dependentEmail.${subject}`,
      "1"
    ),
    phoneLabel: getTextConstants(`enrollment.form.phone.${subject}`),
    stateLabel: getTextConstants(`enrollment.form.state.${subject}`),
    consent: getTextConstants(`enrollment.form.consent.${subject}`),
    marketing: getTextConstants(`enrollment.form.marketing.${subject}`),
    atrial: getTextConstants(`enrollment.form.atrial.${subject}`),
    arrhythmia: getTextConstants(`enrollment.form.arrhythmia.${subject}`),
    hypertension: getTextConstants(`enrollment.form.hypertension.${subject}`),
    dependentButton: getTextConstants(`enrollment.dependentButton.${subject}`),
    submitButton: getTextConstants(`enrollment.form.submitButton.${subject}`),
    enrollButton: getTextConstants(`enrollment.form.enrollButton.${subject}`),
    eligibilityTitle: getTextConstants(
      `enrollment.eligibility.title.${subject}`
    ),
    diseaseTitle: getTextConstants(
      `enrollment.eligibility.diseaseTitle.${subject}`
    ),
    diseaseDescription: getTextConstants(
      `enrollment.eligibility.diseaseDescription.${subject}`
    ),
    failureTitle: getTextConstants(
      `enrollment.eligibility.failureTitle.${subject}`
    ),
    failureDescription: getTextConstants(
      `enrollment.eligibility.failureDescription.${subject}`
    ),
    surgeryTitle: getTextConstants(
      `enrollment.eligibility.surgeryTitle.${subject}`
    ),
    surgeryDescription: getTextConstants(
      `enrollment.eligibility.surgeryDescription.${subject}`
    ),
    restrictionTitle: getTextConstants(
      `enrollment.eligibility.restrictionTitle.${subject}`
    ),
    restrictionDescription: getTextConstants(
      `enrollment.eligibility.restrictionDescription.${subject}`
    ),
    dialysisTitle: getTextConstants(
      `enrollment.eligibility.dialysisTitle.${subject}`
    ),
    dialysisDescription: getTextConstants(
      `enrollment.eligibility.dialysisDescription.${subject}`
    ),
  };

  const [isDuplicateFlag, setIsDuplicateFlag] = useState(false);
  let isDuplicate = [];
  let formValues = [];

  const getPassVal = (
    value,
    passwordValidation,
    setPasswordValidation,
    value1,
    value2
  ) => {
    setPasswordValidation({
      ...passwordValidation,
      ["characterVal"]: value.length > 7 && value.length < 21,
      ["upperVal"]: containsUppercase(value),
      ["lowerVal"]: hasLowerCase(value),
      ["numberVal"]: checkNumber(value),
    });
    return checkPassword(value) && value1 === value2;
  };

  const handleRegistrationChange = (e) => {
    const target = e.target;
    let newValue = target.value;
    const name = target.name;

    if (name === "phoneNumber") newValue = formatPhoneNumber(newValue);
    else if (name === "dateOfBirth" || name === "employeeDateOfBirth")
      newValue = formatDate(newValue);
    else if (name === "firstName" || name === "enroleeFirstName")
      newValue = nameValidator(newValue);
    else if (name === "lastName" || name === "enroleeLastName")
      newValue = nameValidator(newValue);
    else if (name === "confirmPassword") setConfirmPassword(newValue);
    if (target.type === "checkbox") newValue = target.checked;

    if (name !== "confirmPassword") {
      updateRegistrationValues({ ...registrationValues, [name]: newValue });
      formValues = [{ ...registrationValues, [name]: newValue }];
    }
    if (name === "password") {
      getPassVal(
        newValue,
        passwordValidation,
        setPasswordValidation,
        newValue,
        confirmPassword
      );
    }
    if (name === "confirmPassword") {
      getPassVal(
        newValue,
        confirmPasswordValidation,
        setConfirmPasswordValidation,
        newValue,
        registrationValues?.password
      );
    }
    for (let i = 1; i <= dependentEmails.length; i++) {
      if (
        name.includes("dependentEmail") &&
        formValues[0][`dependentEmail${i}`] === formValues[0]["email"]
      ) {
        isDuplicate.push(formValues[0][`dependentEmail${i}`]);
      }
      if (
        name.includes("dependentEmail") &&
        (formValues[0][`dependentEmail${i}`] ===
          formValues[0][`dependentEmail${i + 1}`] ||
          registrationValues[`dependentEmail${i}`] === newValue)
      ) {
        isDuplicate.push(formValues[0][`dependentEmail${i}`]);
      }
    }
    isDuplicate?.length > 0
      ? setIsDuplicateFlag(true)
      : setIsDuplicateFlag(false);
    updateSubmitable(isFormValid(formRef.current));
    updateErrors([]);
  };
  const getDepenedentError = () => {
    const statusObj = {
      // [USER_DEPENDENT_ERROR_1]: "You're not a Kardia Complete user.",
      [USER_DEPENDENT_ERROR_2]: `You cannot add more than ${
        props.rules?.dependentMaxCount
      } ${props.rules?.dependentMaxCount > 1 ? "dependents" : "dependent"}.`,
      [USER_DEPENDENT_ERROR_3]: "This email has already been registered.",
      [USER_DEPENDENT_ERROR_4]: "Change the email address.",
    };
    return statusObj[statusCode];
  };
  const fieldConfigs = {
    employeeFirstName: {
      type: "text",
      label: `${
        sponsor === SPONSOR_HP && subject === SUBJECT_DEPENDENT && "Employee"
      } ${messages.firstNameLabel}`,
      value: registrationValues.employeeFirstName,
      name: "employeeFirstName",
      onChange: handleRegistrationChange,
      validation: ["string"],
    },
    employeeLastName: {
      type: "text",
      label: `${
        sponsor === SPONSOR_HP && subject === SUBJECT_DEPENDENT && "Employee"
      } ${messages.lastNameLabel}`,
      value: registrationValues.employeeLastName,
      name: "employeeLastName",
      onChange: handleRegistrationChange,
      validation: ["string"],
    },
    employeeDateOfBirth: {
      type: "text",
      label: `${
        sponsor === SPONSOR_HP && subject === SUBJECT_DEPENDENT && "Employee"
      } ${messages.dateOfBirthLabel}`,
      value: registrationValues.employeeDateOfBirth,
      name: "employeeDateOfBirth",
      onChange: handleRegistrationChange,
      placeholder: "mm/dd/yyyy",
      validation: ["date"],
    },
    firstName: {
      type: "text",
      label: messages.firstNameLabel,
      value: registrationValues.firstName,
      name: "firstName",
      onChange: handleRegistrationChange,
      validation: ["string"],
      // validation:
      //   sponsor === BOLD
      //     ? subject === SUBJECT_PROVIDER
      //       ? ["isBoldDependentLastName"]
      //       : ["isBoldEmployeeLastName"]
      //     : ["string"],
      credentialValidation: credentialsErros?.first_name_mismatch,
    },
    lastName: {
      type: "text",
      label: messages.lastNameLabel,
      value: registrationValues.lastName,
      name: "lastName",
      onChange: handleRegistrationChange,
      validation: ["string"],
      // validation:
      //   sponsor === BOLD
      //     ? subject === SUBJECT_PROVIDER
      //       ? ["isBoldDependentLastName"]
      //       : ["isBoldEmployeeLastName"]
      //     : ["string"],
      credentialValidation: credentialsErros?.last_name_mismatch,
    },
    dateOfBirth: {
      type: "text",
      label: messages.dateOfBirthLabel,
      value: registrationValues.dateOfBirth,
      name: "dateOfBirth",
      onChange: handleRegistrationChange,
      placeholder: "mm/dd/yyyy",
      validation: ["date"],
      credentialValidation: credentialsErros?.dob_mismatch,
      subText: messages.dateOfBirthSubtext,
    },
    height: {
      type: "label-only",
      label: messages.height,
      subText: messages.heightSubtext,
    },
    feet: {
      type: "select",
      label: "",
      value: registrationValues.feet,
      name: "feet",
      validation: ["selected"],
      defaultOptionName: registrationValues?.feet,
      options: getFormatedSelectInputValueForEnrolmentForm(10),
      placeholder: "Feet",
      onChange: handleRegistrationChange,
    },
    inches: {
      type: "select",
      label: "",
      value: registrationValues?.inches,
      name: "inches",
      validation: ["selected"],
      defaultOptionName: registrationValues?.inches,
      options: getFormatedSelectInputValueForEnrolmentForm(12),
      placeholder: "Inches",
      onChange: handleRegistrationChange,
    },
    sex: {
      type: "select",
      label: messages.sex,
      value: registrationValues?.sex,
      name: "sex",
      onChange: handleRegistrationChange,
      validation: ["selected"],
      defaultOptionName: registrationValues?.sex,
      options: sex,
      placeholder: "Sex",
      subText: messages.sexSubtext,
    },
    email: {
      type: "email",
      label: messages.emailLabel,
      value: registrationValues.email,
      name: "email",
      onChange: handleRegistrationChange,
      validation: ["email"],
      recommendation: true,
      subText: messages.emailSubText,
    },
    password: {
      type: "password",
      label: messages.passwordLabel,
      value: registrationValues.password,
      passwordOpen: passwordOpen,
      setPasswordOpen: setPasswordOpen,
      name: "password",
      onChange: handleRegistrationChange,
      validation: ["password"],
      passwordValidation: passwordValidation,
    },
    confirmPassword: {
      type: "password",
      label: messages.confirmPassowrdLabel,
      value: confirmPassword,
      passwordOpen: confirmPasswordOpen,
      passwordValue: registrationValues?.password,
      setPasswordOpen: setConfirmPasswordOpen,
      name: "confirmPassword",
      onChange: handleRegistrationChange,
      validation: ["string"],
      passwordValidation: confirmPasswordValidation,
    },
    phone: {
      type: "text",
      label: messages.phoneLabel,
      value: registrationValues.phoneNumber,
      name: "phoneNumber",
      onChange: handleRegistrationChange,
      validation: ["phone"],
    },
    state: {
      type: "select",
      label: messages.stateLabel,
      value: registrationValues.state,
      name: "state",
      onChange: handleRegistrationChange,
      validation: ["selected"],
    },
    consent: {
      type: "checkbox",
      label: messages.consent,
      value: registrationValues.consent,
      name: "consentTosPp",
      onChange: handleRegistrationChange,
      validation: ["checked"],
    },
    marketing: {
      type: "checkbox",
      label: messages.marketing,
      value: registrationValues.marketing,
      name: "consentMarketing",
      onChange: handleRegistrationChange,
    },
    atrial: {
      type: "checkbox",
      label: messages.atrial,
      value: registrationValues.atrial,
      name: "atrialFibrillation",
      onChange: handleRegistrationChange,
      // validation: sponsor === SPONSOR_CVS ? ["checked"] : null, //for cvs users aFib checkbox is mandatory
    },
    arrhythmia: {
      type: "checkbox",
      label: messages.arrhythmia,
      value: registrationValues.arrhythmia,
      name: "arrhythmiaDiagnosis",
      onChange: handleRegistrationChange,
      // validation: sponsor === SPONSOR_CVS ? ["checked"] : null, //for cvs users aFib checkbox is mandatory
    },
    hypertension: {
      type: "checkbox",
      label: messages.hypertension,
      value: registrationValues.hypertension,
      name: "hypertensionBox",
      onChange: handleRegistrationChange,
    },
    dependentEmail: {
      type: "email",
      label: messages.dependentEmailLabel,
      value: registrationValues.dependentEmail,
      validation: ["email"],
      name: "dependentEmail",
      onChange: handleRegistrationChange,
      credentialValidation: getDepenedentError(),
    },
  };

  // if (sponsor === SPONSOR_CVS) {
  //   fieldConfigs["atrial"]["validation"] = ["atrial"];
  // }

  const [canAddMoreDependent, setCanAddMoreDependent] = useState(true);
  const addDependentEmail = () => {
    const emails = dependentEmails.slice();
    const index = dependentEmails.length + 1;
    const emailConfig = {
      label: getTextConstants(`enrollment.form.dependentEmail.${subject}`, [
        index,
      ]),
      name: `dependentEmail${index}`,
      type: "email",
      index,
      validation: ["email"],
    };
    emails.push(emailConfig);
    if (emails.length > 2) {
      setCanAddMoreDependent(false);
    }
    updateDependentEmails(emails);
    updateSubmitable(isFormValid(formRef.current));
  };

  const removeDependentEmail = function (i) {
    const emails = dependentEmails.slice();
    // emails.splice(i, 1);
    emails.pop();
    if (emails.length < 3) {
      setCanAddMoreDependent(true);
    }
    updateDependentEmails(emails);
  };
  const callEnroll = () => {
    navigate(`/enroll/${sponsor}/employee`);
    setSubject("employee");
    setDependentSuccess(false);
  };

  /**
   * Rules to determine program id based on program checkbox selection
   * @param {form} formValues
   * @returns program id
   */
  const programId = (formValues) => {
    if (
      !formValues?.atrialFibrillation &&
      !formValues?.arrhythmiaDiagnosis &&
      formValues?.hypertensionBox
    )
      return "Hypertension-Id";
    else if (
      (formValues?.atrialFibrillation || formValues?.arrhythmiaDiagnosis) &&
      formValues?.hypertensionBox
    )
      return "AfibAndHypertension-Id";
    else return "Afib-Id";
  };

  /**
   * modify existing form object based on /create api's payload need
   * @param {form} formValues
   * @returns /create api's payload object
   */
  const getUpdatedformValue = (formValues) => {
    formValues["programId"] = programId(formValues);
    formValues["sponsorSymbol"] = formValues.sponsorSymbol.toUpperCase();
    formValues["statementsOpt"] = {
      atrialFibrillation: formValues?.atrialFibrillation,
      arrhythmiaDiagnosis: formValues?.arrhythmiaDiagnosis,
      hypertension: formValues?.hypertensionBox,
    };

    return formValues;
  };
  const getError = (errors) => {
    let errorObj = {
      first_name_mismatch: null,
      dob_mismatch: null,
      last_name_mismatch: null,
    };
    for (let i = 0; i < errors.length; i++) {
      let code = errors[i]?.code;
      let errorDetail = errors[i]?.detail;
      if (code in errorObj) {
        errorObj[code] = errors[i]?.detail;
      } else if (
        errorDetail === NO_USER_FOUND ||
        code === USER_NOT_EXIST_CODE
      ) {
        handleOpen("You are not eligible user.", code, false);
      } else if (code === CHAT_USER_NOT_CREATED) {
        handleOpen(
          "Failed to create KCC Chat user, Please try again.",
          code,
          false
        );
      } else if (code === USER_EXIST_CODE_CVS_1) {
        handleOpen(
          "This email is already in use in the Kardia app",
          code,
          false
        );
      } else if (
        code === USER_ERRORED_CODE_GENERAL_1 ||
        code === USER_EXIST_CODE_GENERAL_2
      ) {
        handleOpen("You've already enrolled in KardiaComplete", code, false);
      }
    }
    setCredentialsErros(errorObj);
  };

  useEffect(() => {
    updateRegistrationValues({});
  }, [subject]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid(e.target)) {
      const formValues = getFormValues(e.target);
      if (formValues?.confirmPassword) delete formValues?.confirmPassword;
      formValues.sponsorSymbol = formValues.sponsorSymbol.toUpperCase();
      let resp;
      let updatedFormValue;
      updateLoading(true);
      if (subject === SUBJECT_PROVIDER) {
        try {
          resp = await updateDependentInvite(formValues);
          if (resp.errors) {
            if (
              resp?.errors[0]?.code === USER_DEPENDENT_ERROR_1 ||
              resp?.errors[0]?.code === USER_DEPENDENT_ERROR_2 ||
              resp?.errors[0]?.code === USER_DEPENDENT_ERROR_3 ||
              resp?.errors[0]?.code === USER_DEPENDENT_ERROR_4
            ) {
              setStatusCode(resp?.errors[0]?.code);
            } else
              handleOpen(resp.errors[0].title, resp.errors[0]?.code, false);
          }
        } catch (err) {
          updateLoading(false);
          handleOpen("Something went wrong", null, false);
          return;
        }
      } else if (subject === SUBJECT_EMPLOYEE) {
        updatedFormValue = getUpdatedformValue(formValues);

        //below form values to be omitted from post request
        delete updatedFormValue?.hypertensionBox;
        delete updatedFormValue?.atrialFibrillation;
        delete updatedFormValue?.arrhythmiaDiagnosis;

        try {
          resp = await updateRegistration(updatedFormValue); //create account api call
          dispatch(
            userInfoAction({
              auth_token: resp.authToken,
              public_id: resp.userId,
              first_name: resp.firstName,
              last_name: resp.lastName,
              patient_dob: resp.dateOfBirth,
            })
          );

          //call profile setup api on success of create account,
          //this is the step 2 in previous enrolment funnel
          const body = {
            dob: DateTime.fromJSDate(
              new Date(registrationValues.dateOfBirth)
            ).toFormat("yyyy-MM-dd"),
            sex: registrationValues.sex,
            height: parseInt(
              registrationValues?.feet * 30.48 +
                registrationValues?.inches * 2.54
            ),
            conditions: null,
            first_name: registrationValues.firstName,
            last_name: registrationValues.lastName,
            address: null,
            smoker: false,
          };
          try {
            updateLoading(true);
            let resData = await profileAPI(body, {
              auth_token: resp.authToken,
              public_id: resp.userId,
            });
            if (resData.status === 200) {
              let res = await resData.json();
              props.handleNavigate(screenNames.SHIPPING); // on success naviagting to shipping screen
            } else handleOpen("Something went wrong", null, false);
          } catch (err) {
            console.log(err);
          }
        } catch (err) {
          updateLoading(false);
          // handleErrorModalOpen();
          handleOpen("Something went wrong", null, true);
          return;
        }

        if (resp?.errors) {
          // if (sponsor === SPONSOR_CVS) {
          //   console.log(resp?.errors);
          getError(resp?.errors);
          // } else handleOpen(SPONSOR_HP);
        }
      } else if (subject === SUBJECT_DEPENDENT) {
        updatedFormValue = getUpdatedformValue(formValues);
        delete updatedFormValue?.hypertensionBox;
        delete updatedFormValue?.atrialFibrillation;
        delete updatedFormValue?.arrhythmiaDiagnosis;
        try {
          let dependentRes = await updateDependentEnrolment(updatedFormValue);
          resp = await dependentRes.json();
          if (dependentRes?.status >= 400) {
            updateLoading(false);
            if (resp?.errors) {
              getError(resp?.errors);
            } else {
              handleOpen("Something went wrong", null, false);
            }
          } else {
            dispatch(
              userInfoAction({
                auth_token: resp.authToken,
                public_id: resp.userId,
                first_name: resp.firstName,
                last_name: resp.lastName,
                patient_dob: resp.dateOfBirth,
              })
            );
          }
        } catch (err) {
          console.log(err);
          updateLoading(false);
          handleOpen("Something went wrong", null, false);
          return;
        }
      } else {
        return;
      }
      if (resp?.errors) updateErrors(resp?.errors);
      else {
        if (subject === SUBJECT_PROVIDER) {
          setDependentSuccess(true);
        }
      }
      updateLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    subject === SUBJECT_PROVIDER && addDependentEmail();
    if (
      (sponsor === SPONSOR_CVS || sponsor === SPONSOR_NITERRA) &&
      (subject === SUBJECT_DEPENDENT || subject === SUBJECT_PROVIDER)
    ) {
      setSubject("employee");
      navigate(`/enroll/${sponsor}/${SUBJECT_EMPLOYEE}`);
    }
  }, []);
  useEffect(() => updateSubmitable(isFormValid(formRef.current)), []);

  const checkSubmit = () => {
    let check = true;
    if (props.rules?.programValidation) {
      // for cvs users if aFIb is not checked then submit button should not get enabled.
      check =
        sponsor === SPONSOR_CVS
          ? registrationValues?.atrialFibrillation === true ||
            registrationValues?.arrhythmiaDiagnosis === true
          : registrationValues?.atrialFibrillation === true ||
            registrationValues?.hypertensionBox === true;
    }
    return check;
  };

  const getEnrollInfo = () => {
    return (
      <>
        <Grid item xs={6} className={classes["form-row"]}>
          <Input {...fieldConfigs.firstName} />
        </Grid>
        <Grid item xs={6} className={classes["form-row"]}>
          <Input {...fieldConfigs.lastName} />
        </Grid>
        <Grid item xs={12} className={classes["form-row"]}>
          <Input {...fieldConfigs.dateOfBirth} />
        </Grid>
        <Grid item xs={12} className={classes["label-only"]}>
          <Input {...fieldConfigs.height} />
        </Grid>
        <Grid item xs={6} className={classes["no-label-input"]}>
          <Input {...fieldConfigs.feet} />
        </Grid>
        <Grid item xs={6} className={classes["no-label-input"]}>
          <Input {...fieldConfigs.inches} />
        </Grid>
        <Grid item xs={12} className={classes["form-row"]}>
          <Input {...fieldConfigs.sex} />
        </Grid>
      </>
    );
  };
  const getEmployeeInfo = () => {
    return (
      <>
        <Grid item xs={6} className={classes["form-row"]}>
          <Input {...fieldConfigs.employeeFirstName} />
        </Grid>
        <Grid item xs={6} className={classes["form-row"]}>
          <Input {...fieldConfigs.employeeLastName} />
        </Grid>
        <Grid item xs={12} className={classes["form-row"]}>
          <Input {...fieldConfigs.employeeDateOfBirth} />
        </Grid>
      </>
    );
  };
  const getStatusCode = () => {
    const statusObj = {
      [USER_DEPENDENT_ERROR_1]: "You're not a Kardia Complete user",
      [USER_DEPENDENT_ERROR_2]: "You’ve added the maximum number of dependent.",
      // [USER_DEPENDENT_ERROR_3]: "This email has already been registered.",
      [USER_DEPENDENT_ERROR_4]:
        "This account is already in use on our Kardia app, not KardiaComplete.",
    };
    return statusObj[statusCode];
  };

  const errorstatusmessage=statusCode !== null && statusCode !== USER_DEPENDENT_ERROR_3 ? getStatusCode() : null

  return (
    <Shell confirmation={dependentSuccess ? true : false}>
      <Grid container className={classes["gridContainer"]}>
        <Grid item xs={0} md={2} lg={2}></Grid>
        <Grid item xs={12} md={8} lg={8} className={classes.titles}>
          {!dependentSuccess && (
            <StepperMessage
              title={messages.title}
              // subtitle={getSubTitle(props.rules?.dependentMaxCount)}
              subtitle={messages.subtitle}
              activeStep={subject === SUBJECT_PROVIDER ? 1 : 0}
              marginBottom={subject === SUBJECT_PROVIDER ? false : true}
            />
          )}
          {subject !== SUBJECT_PROVIDER && !dependentSuccess && (
            <Typography
              variant="subtitle1"
              className={classes["enrollLink"]}
              style={{
                marginBottom:
                  props.rules?.eligibleUserConstraint === false ? "1vh" : "4vh",
              }}
            >
              Already have a KardiaComplete account?{" "}
              <span
                className={classes.createAccount}
                onClick={() =>
                  typeof props.handleNavigate === "function" &&
                  props.handleNavigate(screenNames.LOGIN)
                }
              >
                Log In
              </span>
            </Typography>
          )}
          {props.rules?.eligibleUserConstraint === false &&
            subject === SUBJECT_EMPLOYEE && (
              <Typography
                variant="subtitle1"
                className={classes["enrollLink"]}
                style={{ marginTop: subject === SUBJECT_PROVIDER && -30 }}
              >
                Are you enrolling as an{" "}
                {subject === SUBJECT_EMPLOYEE
                  ? SUBJECT_DEPENDENT
                  : SUBJECT_EMPLOYEE}
                ?{" "}
                <Link
                  onClick={() =>
                    setSubject(
                      subject === SUBJECT_EMPLOYEE
                        ? SUBJECT_PROVIDER
                        : SUBJECT_EMPLOYEE
                    )
                  }
                  to={`/enroll/${sponsor}/${
                    subject === SUBJECT_EMPLOYEE
                      ? SUBJECT_PROVIDER
                      : SUBJECT_EMPLOYEE
                  }`}
                  className={classes["aLink"]}
                >
                  Click here.
                </Link>
              </Typography>
            )}
        </Grid>
        <Grid item xs={0} md={2} lg={2}></Grid>
        {/* <Grid item xs={2} md = {2} lg = {2}></Grid> */}
        <Grid
          sm={12}
          md={12}
          lg={8}
          container
          order={{ xs: 2, md: 1 }}
          className={classes["backdrop-container"]}
        >
          <Backdrop open={loading} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <form
            onSubmit={handleSubmit}
            className={classes["registration-form"]}
            ref={formRef}
          >
            <input
              type="hidden"
              name="sponsorSymbol"
              value={sponsor}
              data-valid="true"
            />
            {/* {subject === SUBJECT_DEPENDENT && ( */}
            <input type="hidden" name="id" value={code} data-valid="true" />
            {/* )} */}
            {!dependentSuccess && (
              <>
                <Typography
                  variant="h3"
                  className={classes["registration-headline"]}
                >
                  {messages.formTitle}{" "}
                  <span className={classes["all-fields-required"]}>
                    {messages.allFieldsRequired}
                  </span>
                </Typography>
                <Typography variant="subtitle2" className={classes.formText}>
                  {/* {sponsor === SPONSOR_HP
                    ? "Please enter your information as it appears on your insurance card."
                    // : sponsor === SPONSOR_CVS */}
                  {
                    "Please enter the required fields as they are written on your health insurance card."
                  }
                  {/* // : ""} */}
                </Typography>{" "}
              </>
            )}
            {/* <div
              className={`${classes["registration-error-display"]} ${
                errors.length ? classes["registration-display-errors"] : ""
              }`}
            >
              {errors.map((error, arr, i) => (
                <Typography variant="h4" key={error.id}>
                  {error.title === "Error Dependent Does Not Exist" ? (
                    <>
                      Could not find your email in the system, please inform
                      employee to invite you
                    </>
                  ) : (
                    <>
                      Invalid user information. Please verify that you’ve
                      entered the correct information.
                    </>
                  )}
                </Typography>
              ))}
            </div> */}
            <Typography
              variant="p"
              className={classes["registration-subtitle"]}
            >
              {messages.formSubtitle}
            </Typography>
            {sponsor === SPONSOR_HP && subject === SUBJECT_DEPENDENT && (
              <Grid container spacing={1} className={classes["form-fields"]}>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    className={classes["registration-headline"]}
                    style={{ fontSize: 21 }}
                  >
                    Employee Information
                  </Typography>
                </Grid>
                {getEmployeeInfo()}
              </Grid>
            )}
            {(subject === SUBJECT_EMPLOYEE ||
              subject === SUBJECT_DEPENDENT) && (
              <Grid container spacing={1} className={classes["form-fields"]}>
                {sponsor === SPONSOR_HP && subject === SUBJECT_DEPENDENT && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      className={classes["registration-headline"]}
                      style={{ fontSize: 21 }}
                    >
                      Enrollee Information
                    </Typography>
                  </Grid>
                )}

                {getEnrollInfo()}
                <Grid item xs={12} className={classes["form-row"]}>
                  <Input {...fieldConfigs.email} />
                </Grid>
                {/* {subject === SUBJECT_EMPLOYEE && ( */}
                <Grid item xs={12} className={classes["form-row"]}>
                  <Input {...fieldConfigs.password} />
                </Grid>
                <Grid item xs={12} className={classes["form-row"]}>
                  <Input {...fieldConfigs.confirmPassword} />
                </Grid>
                {/* )} */}
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.checkBoxText}>
                    {/* program checkbox title,
                        title varies depending on sponsors */}
                    <span>
                      {textConstants.FORM_PROGRAM_TITLE[sponsor]}
                      {props?.rules?.programValidation === true && (
                        <span className={classes.asterikStyle}>*</span>
                      )}
                    </span>
                  </Typography>
                  {/* `If applicable, please select the statement(s) below that apply to you: ` */}
                  <Typography variant="body2" className={classes.requiredText}>
                    {sponsor === SPONSOR_CVS &&
                      !registrationValues.atrialFibrillation &&
                      !registrationValues.arrhythmiaDiagnosis &&
                      textConstants.ERROR.PROGRAM}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={`${classes["form-consent"]} ${classes["atrialHypertensionStyle"]}`}
                >
                  <Input {...fieldConfigs.atrial} />
                </Grid>
                {sponsor === SPONSOR_CVS && (
                  <Grid
                    item
                    xs={12}
                    className={`${classes["form-consent"]} ${classes["atrialHypertensionStyle"]}`}
                  >
                    <Input {...fieldConfigs.arrhythmia} />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  className={`${classes["form-consent"]} ${classes["atrialHypertensionStyle"]}`}
                >
                  <Input {...fieldConfigs.hypertension} />
                </Grid>
                <Grid item xs={12} className={classes["form-consent"]}>
                  <Input {...fieldConfigs.marketing} />
                </Grid>
                <Grid item xs={12} className={classes["form-consent"]}>
                  <Input {...fieldConfigs.consent} />
                </Grid>
              </Grid>
            )}
            {subject === "provider" && !dependentSuccess && (
              <>
                <Grid container spacing={1} className={classes["form-fields"]}>
                  <Grid item xs={6} className={classes["form-row-dependents"]}>
                    <Input {...fieldConfigs.firstName} />
                  </Grid>
                  <Grid item xs={6} className={classes["form-row-dependents"]}>
                    <Input {...fieldConfigs.lastName} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes["form-row-dependents"]}
                    style={{ marginBottom: 30 }}
                  >
                     <Input  {...fieldConfigs.email} errormessage={errorstatusmessage} />
                  </Grid>
                  {/* <Grid item xs={12} className={classes["form-row"]}>
                    <Input {...fieldConfigs.dependentEmail} />
                  </Grid> */}

                  {dependentEmails.map((email) => {
                    const config = {
                      ...email,
                      credentialValidation: getDepenedentError(),
                      value:
                        registrationValues[`dependentEmail${email.index}`] ||
                        "",
                      onChange: handleRegistrationChange,
                      remove:
                        email.index > 1
                          ? removeDependentEmail.bind(null, email.index - 1)
                          : null,
                    };
                    return (
                      <Grid
                        item
                        xs={12}
                        className={classes["form-row"]}
                        key={email.index}
                      >
                        <Input {...config} />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes["add-dependent-container"]}
                  // style = {{marginTop:25}}
                >
                  {isDuplicateFlag && (
                    <span
                      style={{
                        color: "rgb(204, 61, 63)",
                        fontSize: 14,
                        fontWeight: 700,
                        fontFamily: "DM Sans, sans-serif",
                      }}
                    >
                      duplicate dependent email
                    </span>
                  )}
                  {/* {statusCode !== null && (
                    <div className={classes.redBox}>
                      <img src={ErrorImage} style={{ width: 30, height: 25 }} />
                      <div style={{ marginLeft: 6 }}>{getStatusCode()}</div>
                    </div>
                  )} */}
                  {props.rules?.dependentMaxCount > 1 && (
                    <BorderButton
                      submit={addDependentEmail}
                      text={messages.dependentButton}
                      className={classes["invite-outline-button"]}
                      disabled={dependentEmails.length > 2}
                      fullWidth={true}
                      noMargin={true}
                    />
                  )}
                </Grid>
              </>
            )}
            {!dependentSuccess && (
              <Grid item xs={12} className={classes["submit-button-container"]}>
                <SubmitButton
                  text={
                    subject === "provider"
                      ? messages.submitButton
                      : messages.enrollButton
                  }
                  fullWidth={true}
                  disabled={
                    // sponsor === SPONSOR_HP
                    //   ? (subject === SUBJECT_PROVIDER ? false : !checkSubmit()) || !submitable
                    // :
                    !submitable ||
                    loading ||
                    (subject === SUBJECT_EMPLOYEE &&
                      (!checkSubmit() ||
                        registrationValues?.password !== confirmPassword ||
                        !eachObjectKeyTrue(passwordValidation) ||
                        !eachObjectKeyTrue(confirmPasswordValidation)))
                  }
                />
              </Grid>
            )}
            {dependentSuccess && (
              <div className={classes.dependentsuccess}>
                <img src={DependentSuccessImage} />
                <Typography variant="h4" className={classes.dependentHeader}>
                  Dependent Added
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.dependentSubHeader}
                >
                  Your dependent will receive an invitation email from
                  support@alivecor.com to begin their enrollment.
                </Typography>
                <FilledButton
                  // sponsor={params.sponsor}
                  text={`ENROLL MYSELF`}
                  // width={`${isMobile ? "80vw" :  isTab? "37vw" : "18.6vw"}`}
                  width={"100%"}
                  blockSize={"100%"}
                  noMargin={true}
                  submit={callEnroll}
                  fontSize={"0.75rem"}
                  padding={"10px 0"}
                />
              </div>
            )}
          </form>
        </Grid>
        <Grid item xs={2} md={2} lg={2}></Grid>
        {/* <Grid item md={6} xs={12} order={{ xs: 1, md: 2 }}>
          <div className={classes.eligibility}>
            <Typography variant="p" className={classes["eligibility-intro"]}>
              {messages.eligibilityTitle}:
            </Typography>
            <Typography
              variant="h4"
              className={classes["eligibility-headline"]}
            >
              {messages.diseaseTitle}
            </Typography>
            <Typography variant="p" className={classes["eligibility-label"]}>
              {messages.diseaseDescription}
            </Typography>
            <Typography
              variant="h4"
              className={classes["eligibility-headline"]}
            >
              {messages.failureTitle}
            </Typography>
            <Typography variant="p" className={classes["eligibility-label"]}>
              {messages.failureDescription}
            </Typography>
            <Typography
              variant="h4"
              className={classes["eligibility-headline"]}
            >
              {messages.surgeryTitle}
            </Typography>
            <Typography variant="p" className={classes["eligibility-label"]}>
              {messages.surgeryDescription}
            </Typography>
            <Typography
              variant="h4"
              className={classes["eligibility-headline"]}
            >
              {messages.restrictionTitle}
            </Typography>
            <Typography variant="p" className={classes["eligibility-label"]}>
              {messages.restrictionDescription}
            </Typography>
            <Typography
              variant="h4"
              className={classes["eligibility-headline"]}
            >
              {messages.dialysisTitle}
            </Typography>
            <Typography variant="p" className={classes["eligibility-label"]}>
              {messages.dialysisDescription}
            </Typography>
          </div>
        </Grid> */}
      </Grid>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <ErrorModal
            handleClose={handleClose}
            open={open.open}
            target={open.target}
            message={open.message}
            code={open.code}
            subText={
              <>
                <span>Please contact </span>
                <a href="mailto:support@alivecor.com">support@alivecor.com.</a>
              </>
            }
          />
        </Modal>
      </div>
    </Shell>
  );
};

export default Register;
